import { Suspense, lazy, useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import { Col, Container, Row } from 'react-bootstrap';
import { v5 as uuidv5 } from 'uuid';
import { useInjectStyles, useLazyComponentPreload, useResultRouteQueryResponse, useUrlQueryLocationString } from '@medifind/hooks';
import { IconRightCaret } from '@medifind/icons';
import { getInfusionCentersResults, getMasterMetadata } from '@medifind/interface';
import { useMFModal } from '@medifind/popups';
import { useLinkConfirmation, usePageChanger } from '@medifind/router';
import { LocationFilter, RadiusFilter } from '@medifind/search-results';
import { Button, MFLink, registerReusableComponent } from '@medifind/shared-basic-components';
import { fillTemplate, getNullList, getValueMediaBreakpointDown, interpolateColor, scrollToId, useMediaQueryDown } from '@medifind/utils';
import {
  clearInfusionCenterFilters,
  defaultCountry,
  infusionCenterFiltersDependencies,
  logEventGA4,
  setAgreedToICTerms,
  useFilterContext,
  useGenericRadiusFilter,
  useInfusionCenterAmenitiesFilter,
  useInfusionCenterDaysOfOperationFilter,
  useInfusionCenterHoursOfOperationFilter,
  useInfusionCenterInsurancesFilter,
  useInfusionCenterServicesFilter,
  useInfusionCenterTypesFilter,
  useLocationStore,
  useWidgetInfusionCenterSearch,
  withFilters,
} from '@medifind/zustand';
import styles from './infusion-center-search.module.scss';
const Map = lazy(() => import('@medifind/lazy-loadable/lib/shared-basic-components/maps/LazyGoogleMap'));
const Grid = lazy(() => import('@medifind/lazy-loadable/lib/search-results/LazyGrid'));
const InfusionCenterFiltersGroup = lazy(() => import('@medifind/lazy-loadable/lib/search-results/LazyInfusionCenterFiltersGroup'));
const InfusionCenterCard = lazy(() => import('@medifind/lazy-loadable/lib/search-results/LazyInfusionCenterCard'));
const InfusionCenterDetail = lazy(() => import('@medifind/lazy-loadable/lib/search-results/LazyInfusionCenterDetail'));

const getWorkingHr = (hours) => {
  const hourRange = hours?.map((item) => JSON.parse(item));
  return hourRange.map((item) => {
    switch (item.open) {
      case '6:00 AM':
        return 'Morning';
      case '11:00 AM':
        return 'Afternoon';
      case '5:00 PM':
        return 'Evening';
      case '12:01 AM':
        return 'Night';

      default:
        return null;
    }
  });
};

const InfusionCenterSearch = ({
  className,
  tagLine,
  size = 10,
  onLocationChange,
  onPageChange,
  onSearchClick,
  onLoad,
  onRender,
  onCenterClick,
  showMap = true,
  headerHTMLTemplate = '',
  subHeaderHTMLTemplate = '',
  termsHeaderHTMLTemplate = '',
  termsHTMLTemplate = '',
  extraTermsHTMLTemplate = '',
  searchBoxFooterHTMLTemplate = '',
  footerHTMLTemplate = '',
  requireTerms = true,
  classes = {},
  style = {},
  injectStyle = {},
  interstitial,
  rootStyles,
  countryCodes,
  invertedPoweredByIcon = false,
  poweredByIcon = {
    icon: invertedPoweredByIcon
      ? 'https://cdn.medifind.com/assets/Powered-By-MediFind-Knockout.png'
      : 'https://cdn.medifind.com/assets/Powered-By-MediFind.png',
    altText: 'Powered By MediFind Logo',
  },
  termsUrl = 'https://www.medifind.com/terms',
  infusionCenterLabel = 'What is the infusion center name?',
  locationLabel = 'Where are you located?',
  radiusLabel = 'How far are you willing to travel?',
  openInNewTab = false,
  noGutters = false,
  noTopBorder = false,
  noTopPadding = false,
  fixedHeaderSize = {},
  showRadius = true,
  medication = null,
  excludeFilters = [],
  liveConfiguration = false,
  ...props
}) => {
  const filters = useFilterContext();
  filters.useEnableFilters({ exclude: excludeFilters?.map((x) => configNameFiltersMapping[x]) });
  const [selectedCenter, setSelectedCenter] = useState();
  const [masterMetadata, setMasterMetadata] = useState();
  useLazyComponentPreload([Map, Grid, InfusionCenterFiltersGroup, InfusionCenterCard]);
  const { agreedToICTerms } = useWidgetInfusionCenterSearch();
  const isSmall = useMediaQueryDown('md');
  const { MFModal, openModal } = useMFModal();

  const scrollerRef = useRef(null);
  const zoomRef = useRef(null);
  const location = useLocationStore();

  const { page, handlePageChange } = usePageChanger(null, [...filters.valueDependencies, location]);
  useUrlQueryLocationString();
  const [hightlight, setHighlight] = useState(null);
  const templateFields = {};

  // const {
  //   handleSearchRouteResponse,
  //   error,
  //   notFound,
  //   unauthorized,
  //   results: centers,
  //   totalResults,
  // } = useResultRouteResponse(
  //   {},
  //   (error) => {
  //     if (error !== null) {
  //       logEventGA4(
  //         'exception',
  //         { type: 'ic_search', method: error.code, description: error.message, item_id: 'infusion-center-finder' },
  //         uuidv5(`infusion-center-finder`, '00000000-0000-0000-0000-000000000000'),
  //       );
  //     }
  //   },
  //   onSuccess,
  // );

  useEffect(() => {
    if (countryCodes) {
      defaultCountry(countryCodes);
    }
  }, [countryCodes, location]);

  useEffect(() => {
    onLoad && onLoad();
    registerReusableComponent(MFLink, 'mf-link', { rel: 'noopener noreferrer', target: '_blank' });
    getMasterMetadata().then((metaData) => {
      setMasterMetadata(metaData?.result);
    });
  }, []);

  const variables = useMemo(() => {
    const { lat: latitude, lon: longitude, stateProvinceCode } = location || {};
    const {
      genericRadius,
      infusionCenterServices,
      infusionCenterDaysOfOperation,
      infusionCenterHoursOfOperation,
      infusionCenterAmenities,
      infusionCenterInsurances,
      infusionCenterTypes,
    } = filters.values;
    const { infusionCenterInsurances: icInsEnabledState } = filters.enabledStates;
    const selectedInsurance = icInsEnabledState.selected.map((ins) => ins.value);
    const insuranceVar = [...new Set([...infusionCenterInsurances, ...selectedInsurance])];
    return {
      page: page,
      size: size,
      lat: latitude?.toString(),
      lon: longitude?.toString(),
      radius: genericRadius,
      stateProvinceCode: stateProvinceCode,
      service: infusionCenterServices && infusionCenterServices.length > 0 ? infusionCenterServices : null,
      day: infusionCenterDaysOfOperation && infusionCenterDaysOfOperation.length > 0 ? infusionCenterDaysOfOperation : null,
      workingHr: infusionCenterHoursOfOperation?.length ? getWorkingHr(infusionCenterHoursOfOperation) : null,
      amenity: infusionCenterAmenities && infusionCenterAmenities.length > 0 ? infusionCenterAmenities : null,
      insurance: insuranceVar && insuranceVar.length > 0 ? insuranceVar : null,
      type: infusionCenterTypes ? [infusionCenterTypes] : null,
      sort: 'distance',
      medication: medication,
    };
  }, [page, location, ...filters.dependencies]);

  const {
    error,
    results: centers,
    totalResults,
  } = useResultRouteQueryResponse({
    queryKey: ['getInfusionCentersResults', variables],
    queryFn: async () => {
      return getInfusionCentersResults({ payload: variables });
    },
    onError: (error) => {
      if (error !== null) {
        logEventGA4(
          'exception',
          { type: 'ic_search', method: error.code, description: error.message, item_id: 'infusion-center-finder' },
          uuidv5(`infusion-center-finder`, '00000000-0000-0000-0000-000000000000'),
        );
      }
    },
    enabled: agreedToICTerms,
  });

  useLinkConfirmation(
    {
      title: interstitial?.title ?? 'You are about to leave ' + window.location.origin.split('/')[2],
      message:
        interstitial?.body ??
        `You are about to leave ${window.location.origin.split('/')[2]} and continue to an independently operated external website.`,
      lock: !!interstitial,
      continueButtonText: interstitial?.continueText ?? 'Continue',
      cancelButtonText: interstitial?.cancelText ?? 'Cancel',
      style: style?.interstitial,
      classes: classes?.interstitial,
    },
    [liveConfiguration ? {} : false],
  );

  onRender && setTimeout(onRender, 0);

  useEffect(() => {
    onLocationChange && onLocationChange(location);
  }, [location]);

  useEffect(() => {
    onPageChange && onPageChange(location);
  }, [page]);

  useEffect(() => {
    if (hightlight) {
      const timer = setTimeout(() => {
        setHighlight(null);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [hightlight]);

  const handleMarkerClick = (id, { domEvent }) => {
    if (isSmall) scrollToId(`card_infusion-center_${id}`, undefined, 20 + (getValueMediaBreakpointDown(fixedHeaderSize) ?? 0), null);
    else scrollToId(`card_infusion-center_${id}`, undefined, 20, scrollerRef.current);
    setHighlight(id);
    logEventGA4('ic_search_map_marker', {
      method: 'marker_click',
      item_id: id,
      location: [location.countryCode, location.stateProvinceCode, location.zipPostalCode].join(' | '),
    });
  };

  const handleSearchClick = () => {
    logEventGA4('ic_search', {
      location: [location.countryCode, location.stateProvinceCode, location.zipPostalCode].join(' | '),
    });
    const searchHandler = () => {
      setAgreedToICTerms();
      scrollToId('result-section-top', undefined, getValueMediaBreakpointDown(fixedHeaderSize) ?? 0);
    };
    const acceptTerms = onSearchClick && onSearchClick();
    if (!!acceptTerms && acceptTerms instanceof Promise) {
      acceptTerms
        .then((res) => {
          searchHandler();
        })
        .catch((err) => {
          return true;
        });
    } else {
      searchHandler();
    }
  };

  const handleOpenModal = (internalId) => {
    logEventGA4('ic_detail', { item_id: internalId });
    setSelectedCenter(internalId);
    openModal();
  };

  const handleGetDirection = (internalId) => {
    if (internalId) {
      logEventGA4('ic_direction', { item_id: internalId });
    }
  };

  const markerList = useMemo(() => {
    if (centers) {
      return (centers || []).map(({ internalId, location: { latitude, longitude }, centerName }) => {
        return {
          id: internalId,
          lat: parseFloat(latitude),
          lon: parseFloat(longitude),
          title: centerName,
        };
      });
    } else {
      return [];
    }
  }, [centers]);

  useInjectStyles(injectStyle);

  return (
    <div
      className={classNames(styles['wrapper'], className, classes.widget)}
      style={{
        ...(style.widget || {}),
        ...(rootStyles || {}),
        '--fixed-header-size': `${getValueMediaBreakpointDown(fixedHeaderSize) ?? 0}px`,
      }}
    >
      {noTopBorder ? null : <div className={classNames(styles['top-border'], classes.topBorder)} style={style.topBorder || {}}></div>}
      <Container
        className={classNames(styles['container'], { [styles['no-gutters']]: noGutters }, classes.container)}
        style={style.container || {}}
      >
        <div className={classNames(styles['content-wrapper'], classes.contentWrapper)} style={style.contentWrapper || {}}>
          <Row>
            <Col>
              <div className={classNames(styles['widget-menu'])}>
                <div className={classNames(styles['widget-menu__links'])}>
                  For Infusion Centers Only
                  <br />
                  <MFLink to="/infusion-center/add" target="_blank">
                    Add
                  </MFLink>{' '}
                  |{' '}
                  <MFLink to="/infusion-center/update" target="_blank">
                    Update
                  </MFLink>
                </div>
              </div>
            </Col>
          </Row>

          <Row className={classNames(styles['row'], classes.row)} style={style.row || {}}>
            <Col xs={12} className={classNames(styles['col'], styles['header__col'], classes.col)} style={style.col || {}}>
              {headerHTMLTemplate && (
                <h1
                  className={classNames(styles['header'], { [styles['no-top-padding']]: noTopPadding }, classes.headerHTMLTemplate)}
                  style={style.headerHTMLTemplate || {}}
                  dangerouslySetInnerHTML={{ __html: fillTemplate(headerHTMLTemplate, templateFields) }}
                ></h1>
              )}
              {subHeaderHTMLTemplate && (
                <div
                  className={classNames(styles['sub-heading'], classes.subHeaderHTMLTemplate)}
                  style={style.subHeaderHTMLTemplate || {}}
                  dangerouslySetInnerHTML={{ __html: fillTemplate(subHeaderHTMLTemplate, templateFields) }}
                ></div>
              )}
              {tagLine && (
                <>
                  <hr className={classNames(styles['hr'], classes.hr)} style={style.hr || {}} />
                  <div className={classNames(styles['icon-text'], classes.iconText)} style={style.iconText || {}}>
                    {tagLine.icon && (
                      <div className={classNames(styles['icon'], classes.icon)} style={style.icon || {}}>
                        {typeof tagLine.icon === 'string' ? (
                          <img src={tagLine.icon} width="130" height="110" alt={'Tag Line'} style={style.iconImg || {}} />
                        ) : (
                          tagLine.icon
                        )}
                      </div>
                    )}
                    <div
                      className={classNames(styles['pop-text'], styles['text'], classes.tagLineHtmlTemplate)}
                      style={style.tagLineHtmlTemplate || {}}
                      dangerouslySetInnerHTML={{ __html: fillTemplate(tagLine.htmlTemplate ?? '', templateFields) }}
                    ></div>
                  </div>
                  <hr className={classNames(styles['hr'], classes.hr)} style={{ marginBottom: '1rem', ...(style.hr || {}) }} />
                </>
              )}
              {requireTerms && (
                <div className={classNames(styles['search-box'], classes.searchBox)} style={style.searchBox || {}}>
                  <div className={classNames(styles['powered-by'], classes.poweredBy)} style={style.poweredBy || {}}>
                    <div className={classNames(styles['icon'], classes.poweredByIcon)} style={style.poweredByIcon || {}}>
                      <img
                        src={poweredByIcon?.icon}
                        width={poweredByIcon?.width || 150}
                        style={{ width: poweredByIcon?.width || 150 }}
                        alt={poweredByIcon?.altText}
                      />
                    </div>
                    {termsHeaderHTMLTemplate && (
                      <div
                        className={classNames(styles['pop-text'], styles['text'], classes.termsHeaderHTMLTemplate)}
                        style={style.termsHeaderHTMLTemplate || {}}
                        dangerouslySetInnerHTML={{ __html: fillTemplate(termsHeaderHTMLTemplate, templateFields) }}
                      ></div>
                    )}
                  </div>
                  <hr className={classNames(styles['hr'], classes.hr)} style={style.hr || {}} />
                  <div className={classNames(styles['search-form'], classes.searchForm)} style={style.searchForm || {}}>
                    <div
                      className={classNames(styles['search-form-fields'], classes.searchFormFields)}
                      style={style.searchFormFields || {}}
                    >
                      {/* If infusion center name filter is required */}
                      {/* <InfusionCenterFilter
                      classes={{
                        input: styles['autocomplete-input-color'],
                        icon: styles['autocomplete-icon-color'],
                        locationIcon: styles['autocomplete-icon-color'],
                      }}
                      style={{
                        input: style.selectorInput,
                        icon: style.selectorIcon,
                      }}
                      label={infusionCenterLabel}
                      countryCodesFilter={countryCodes}
                    /> */}
                      <LocationFilter
                        showClearButton
                        classes={{
                          input: styles['autocomplete-input-color'],
                          icon: styles['autocomplete-icon-color'],
                          locationIcon: styles['autocomplete-icon-color'],
                          ...classes.locationFilter,
                        }}
                        style={{
                          input: style.selectorInput,
                          icon: style.selectorIcon,
                          ...style.locationFilter,
                        }}
                        label={locationLabel}
                        countryCodesFilter={countryCodes}
                      />
                      {showRadius ? (
                        <RadiusFilter
                          filter={filters.states.genericRadius}
                          classes={{ select: styles['select-input-color'], ...classes.radiusFilter }}
                          label={radiusLabel}
                          maxFidelity={countryCodes ? 5 : 6}
                          style={{
                            input: style.selectorInput,
                            icon: style.selectorIcon,
                            ...style.radiusFilter,
                          }}
                          hideReset
                        />
                      ) : null}
                    </div>
                    <div className={classNames(styles['search-container'], classes.searchContainer)} style={style.searchContainer || {}}>
                      <Button
                        classes={{
                          container: classNames(styles['search-button'], classes.searchButton),
                          content: classNames(styles['search-button__content'], classes.searchButtonContent),
                          ...classes.searchButton,
                        }}
                        style={{
                          container: style.searchButton,
                          content: style.searchButtonContent,
                          ...style.searchButton,
                        }}
                        color="blue"
                        label="Search"
                        icon={<IconRightCaret color={style.searchButton?.color ?? style.searchButtonContent?.color ?? 'white'} />}
                        onClick={handleSearchClick}
                      />
                      {termsUrl && (
                        <div className={classNames(styles['search-terms'], classes.searchTerms)} style={style.searchTerms || {}}>
                          {termsHTMLTemplate ? (
                            <span dangerouslySetInnerHTML={{ __html: fillTemplate(termsHTMLTemplate, templateFields) }}></span>
                          ) : (
                            <>
                              By clicking &ldquo;Search&rdquo; you are agreeing to our{' '}
                              <MFLink href={termsUrl} target="_blank" rel="noreferrer" style={style.searchTermsLink || {}}>
                                Terms&nbsp;&amp;&nbsp;Conditions
                              </MFLink>
                            </>
                          )}
                          {extraTermsHTMLTemplate && (
                            <span dangerouslySetInnerHTML={{ __html: fillTemplate(extraTermsHTMLTemplate, templateFields) }}></span>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </Col>
          </Row>
          {searchBoxFooterHTMLTemplate && (
            <Row className={classNames(styles['row'], classes.row)} style={style.row || {}}>
              <Col
                className={classNames(styles['col'], classes.col, classes.searchBoxFooterHTMLTemplate)}
                style={{ ...(style.col || {}), ...(style.searchBoxFooterHTMLTemplate || {}) }}
              >
                <span dangerouslySetInnerHTML={{ __html: fillTemplate(searchBoxFooterHTMLTemplate, templateFields) }}></span>
              </Col>
            </Row>
          )}
          <div id="result-section-top"></div>
          {(agreedToICTerms || !requireTerms) && (
            // Fix scrolling on clicking search for the first time, due to Lazy loading of children
            <div className={styles['results-wrapper']}>
              <Suspense fallback={<div className="fallback" />}>
                <Row className={classNames(styles['row'], classes.row)} style={style.row || {}}>
                  <Col xs={12} className={classNames(styles['col'], styles['search-header__col'])}>
                    <InfusionCenterFiltersGroup
                      totalResults={totalResults}
                      maxFidelity={5}
                      classes={{
                        filters: styles['filters--override'],
                        sortContainer: styles['sort--override'],
                        moreFiltersButton: styles['filters--more'],
                        locationFilter: {
                          input: styles['autocomplete-input-color'],
                          icon: styles['autocomplete-icon-color'],
                          locationIcon: styles['autocomplete-icon-color'],
                          ...classes.locationFilter,
                        },
                      }}
                      smallAt="md"
                      countryCodesFilter={countryCodes}
                      showInlineFilter
                      masterMetadata={masterMetadata}
                      style={{
                        ...style,
                        locationFilter: {
                          input: style.selectorInput,
                          icon: style.selectorIcon,
                          ...style.locationFilter,
                        },
                      }}
                    />
                  </Col>
                </Row>
                <Row className={classNames(styles['row'], classes.row)} style={style.row || {}}>
                  <Col
                    xs={{ span: 12, order: 2 }}
                    lg={{ span: showMap ? 6 : 12, order: 1 }}
                    className={classNames(styles['col'], classes.col)}
                    style={{ padding: 0, ...(style.col || {}) }}
                  >
                    <div className={styles['results__container']} ref={scrollerRef}>
                      <div id="results-top"></div>
                      <Grid
                        error={error}
                        maxColumnCount={1}
                        items={(centers || getNullList(size)).map((center, i) => (
                          <InfusionCenterCard
                            key={`center-${i}`}
                            center={center}
                            classes={{ headerDistance: styles['card__header-distance'] }}
                            onGetDirection={() => handleGetDirection(center?.internalId)}
                            style={{
                              ...(style.card || {}),
                              ...(hightlight === center?.internalId
                                ? {
                                    backgroundColor: interpolateColor(
                                      style.card?.backgroundColor || '#ffffff00',
                                      rootStyles['--color'],
                                      20,
                                    ),
                                  }
                                : {}),
                            }}
                            onClick={() => handleOpenModal(center)}
                            smallAt={['xs', 'sm', 'lg', 'xl', 'xxl']}
                            showPhone={true}
                            showFax={false}
                            showAddress={true}
                            showEmail={false}
                            showWebsite={true}
                            showViewMoreButton={true}
                            showDirectionButton={true}
                            showDistance={true}
                            showTodayWorkHrs={true}
                          />
                        ))}
                        id={'infusion-centers-list'}
                        size={size}
                        pagination={{
                          noResults: '0 Infusion Centers Found',
                          page,
                          totalResults,
                          onPageChange: handlePageChange,
                          type: 'event',
                          forceScroll: true,
                          gid: 'results-top',
                          scrollOffset: 200,
                          scrollerRef: isSmall ? null : scrollerRef,
                        }}
                        noBorder
                        addGutters={false}
                      />
                    </div>
                  </Col>
                  {showMap && (
                    <Col
                      xs={{ span: 12, order: 1 }}
                      lg={{ span: 6, order: 2 }}
                      className={classNames(styles['col'], styles['map__col'], classes.col)}
                      style={style.col || {}}
                    >
                      <Map
                        markers={markerList}
                        zoomTo={zoomRef}
                        defaultCenter={location}
                        onMarkerClick={handleMarkerClick}
                        useImportantStyles={process.env.NX_APP !== 'MediFind'}
                        style={{ height: '100%', maxHeight: '100%', width: '100%' }}
                      />
                    </Col>
                  )}
                </Row>
                <MFModal title={selectedCenter?.centerName} fullWidth style={style}>
                  <InfusionCenterDetail internalId={selectedCenter?.internalId} style={style} />
                </MFModal>
              </Suspense>
            </div>
          )}
          {footerHTMLTemplate && (
            <Row className={classNames(styles['row'], classes.row)} style={style.row || {}}>
              <Col
                className={classNames(styles['col'], classes.col, styles.footer, classes.footerHTMLTemplate)}
                style={{ padding: 0, ...(style.col || {}), ...(style.footerHTMLTemplate || {}) }}
              >
                <span dangerouslySetInnerHTML={{ __html: fillTemplate(footerHTMLTemplate, templateFields) }}></span>
              </Col>
            </Row>
          )}
        </div>
      </Container>
      {noTopBorder ? null : (
        <div
          className={classNames(styles['top-border'], classes.topBorder)}
          style={{
            ...(style.topBorder || {}),
            marginTop: '20px',
          }}
        ></div>
      )}
    </div>
  );
};

const configNameFiltersMapping = {
  types: 'infusionCenterTypes',
  amenities: 'infusionCenterAmenities',
  services: 'infusionCenterServices',
  distance: 'genericRadius',
  insurances: 'infusionCenterInsurances',
  hoursOfOperation: 'infusionCenterHoursOfOperation',
  daysOfOperation: 'infusionCenterDaysOfOperation',
};
const component = withFilters(
  [
    useGenericRadiusFilter,
    useInfusionCenterServicesFilter,
    useInfusionCenterDaysOfOperationFilter,
    useInfusionCenterHoursOfOperationFilter,
    useInfusionCenterAmenitiesFilter,
    useInfusionCenterInsurancesFilter,
    useInfusionCenterTypesFilter,
  ],
  [infusionCenterFiltersDependencies],
  [clearInfusionCenterFilters],
)(InfusionCenterSearch);
export { component as default, component as InfusionCenterSearch };
